import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { EventTrigger, useInstrumentation } from 'newskit';
import {
    BannerDimensionsAndSpacing,
    BannerPosition,
    CTAContentWrapper,
    CloseButton,
    InviteMembersButton,
    TextContentWrapper,
} from './styles';
import Close from '../Icons/Close';
import useCookies from '../useCookies';
import { useAppConfig } from '@times-web/utils';

const BANNER_SHOWN_ON_KEY = 'householding_banner_shown_on';
const HAS_BANNER_BEEN_CLOSED_KEY = 'householding_banner_has_closed';

type Subscription = {
    proposition: {
        groupAccountType: string;
    };
};

const useManageBannerInRelationToAdSpace = (isBannerShowing: boolean) => {
    useEffect(() => {
        // To prevent banner from covering ad space.
        const mainContentWithAd = document.getElementById('main-container-ad');
        if (isBannerShowing) {
            mainContentWithAd?.classList.add('sticky-header-padding');
        }
        return () => {
            mainContentWithAd?.classList.remove('sticky-header-padding');
        };
    }, [isBannerShowing]);
};

const useMarkInitialOpeningOfBanner = (isBannerShowing: boolean) => {
    useEffect(() => {
        if (isBannerShowing && !localStorage.getItem(BANNER_SHOWN_ON_KEY)) {
            localStorage.setItem(BANNER_SHOWN_ON_KEY, new Date().toISOString());
        }
    }, [isBannerShowing]);
};

const hasMoreThanTwoWeeksElapsedSinceBannerFirstShown = () => {
    const bannerFirstShownOn = localStorage.getItem(BANNER_SHOWN_ON_KEY);
    if (!bannerFirstShownOn) {
        return false;
    }
    const startDate = new Date(bannerFirstShownOn);
    const today = new Date();

    const differenceInDays = differenceInCalendarDays(today, startDate);
    return differenceInDays > 14;
};

export const HouseHoldingBanner = () => {
    const [isBannerShowing, setIsBannerShowing] = useState(false);
    const { getCookieValue } = useCookies();
    const { fireEvent } = useInstrumentation();
    useManageBannerInRelationToAdSpace(isBannerShowing);
    useMarkInitialOpeningOfBanner(isBannerShowing);

    const {
        account: { url: accountUrl },
    } = useAppConfig();

    const getJSONCookie = useCallback(
        (name: string) => {
            return getCookieValue(name);
        },
        [getCookieValue]
    );

    const acs_tnl = getJSONCookie('acs_tnl');

    const onClose = () => {
        fireEvent({
            originator: 'householding duo banner',
            trigger: EventTrigger.Click,
            context: {
                event_navigation_action: 'navigation',
                event_navigation_name: 'householding duo banner:close',
                event_navigation_browsing_method: 'click',
            },
        });
        localStorage.setItem(HAS_BANNER_BEEN_CLOSED_KEY, 'true');
        setIsBannerShowing(false);
    };

    const onNavigateToMyFamily = () => {
        fireEvent({
            originator: 'householding duo banner',
            trigger: EventTrigger.Click,
            context: {
                event_navigation_action: 'navigation',
                event_navigation_name: 'householding duo banner:invite members',
                event_navigation_browsing_method: 'click',
            },
        });
        localStorage.setItem(HAS_BANNER_BEEN_CLOSED_KEY, 'true');
        window.location.href = `${accountUrl}/family`;
    };

    useEffect(() => {
        if (!acs_tnl) return;
        const getIsPrimaryUser = async () => {
            const userData = await axios.get(
                '/api/get-subscription-account-types',
                {
                    withCredentials: true,
                }
            );

            const isPrimaryUser = userData?.data?.user?.subscriptions.some(
                (sub: Subscription) =>
                    sub?.proposition?.groupAccountType === 'PRIMARY'
            );

            const ownedShares = userData?.data?.user?.groupAccountsV2?.[0]?.ownedShares

            const isDuo = ownedShares?.maxShares === 1;
            const hasNotShared = ownedShares?.shares?.length === 0;

            const bannerHasNotBeenInvalidated =
                !hasMoreThanTwoWeeksElapsedSinceBannerFirstShown() &&
                !localStorage.getItem(HAS_BANNER_BEEN_CLOSED_KEY);

            if (isPrimaryUser && isDuo && hasNotShared && bannerHasNotBeenInvalidated) {
                setIsBannerShowing(true);
            }
        };
        getIsPrimaryUser();
    }, [acs_tnl]);

    if (!isBannerShowing) {
        return null;
    }

    return (
        <BannerPosition>
            <BannerDimensionsAndSpacing>
                <TextContentWrapper>
                    <h2>Share your subscription</h2>
                    <p>
                      Invite a friend or family member to enjoy The Times through Bonus Accounts – at no extra cost.
                    </p>
                </TextContentWrapper>

                <CTAContentWrapper>
                    <InviteMembersButton onClick={onNavigateToMyFamily}>
                      Invite members
                    </InviteMembersButton>
                </CTAContentWrapper>
                <CloseButton
                    className="close"
                    aria-label="close"
                    data-testid="close"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <Close size={10} />
                </CloseButton>
            </BannerDimensionsAndSpacing>
        </BannerPosition>
    );
};
