import React from 'react';
import { Block, Stack } from '@/components/ui';
import { LoadingBlock } from './styles';

export const ArticleStackLoading = ({
    isBrowseByDay,
}: {
    isBrowseByDay?: boolean;
}) => {
    return (
        <Stack direction="row" justifyContent="space-between" flexGrow={1}>
            <Block marginInlineEnd={{ all: 'space060' }}>
                <LoadingBlock
                    marginBlockEnd="space030"
                    height="sizing020"
                    width="185px"
                    isBrowseByDay={isBrowseByDay}
                />
                <LoadingBlock
                    marginBlockEnd="space030"
                    height="sizing050"
                    isBrowseByDay={isBrowseByDay}
                />
                <LoadingBlock
                    marginBlockEnd="space030"
                    height="sizing080"
                    isBrowseByDay={isBrowseByDay}
                />
                <LoadingBlock
                    marginBlockEnd="space030"
                    height="sizing020"
                    width="110px"
                    isBrowseByDay={isBrowseByDay}
                />
            </Block>
            <LoadingBlock
                height="93px"
                width="140px"
                isBrowseByDay={isBrowseByDay}
            />
        </Stack>
    );
};
