import React from 'react';
import {
    CuratedMedia,
    CuratedSectionLink,
    ExpirableFlagsProps,
    ContentTypeText,
} from '@times-web/page-channel/src/queries/curatedPage';

export enum ComponentType {
    Web = 'web-component',
    React = 'react-component',
    Editorial = 'editorial-component',
}

export type SliceComponent =
    | {
          type: ComponentType.Web;
          url: string;
          name: string;
          args?: { [key: string]: unknown };
      }
    | {
          type: ComponentType.React;
          node: React.ReactNode;
          name?: string;
      }
    | {
          type: ComponentType.Editorial;
          name: string;
          args?: { [key: string]: string };
      };

type TagType = {
    label: string;
    href: string | null;
};

type Byline =
    | string
    | {
          name: string;
          slug?: string;
      };

type Crop = { url: string; ratio: string };

type Images = {
    caption?: string;
    alt?: string;
    credits?: string;
    crops: Crop[];
};

type Video = {
    caption?: string;
    brightcoveAccountId: string;
    brightcovePolicyKey: string;
    brightcoveVideoId: string;
    brightcovePlayerId: string;
    posterImage: {
        crops: Crop[];
        alt?: string;
    };
    is360: boolean | null;
    title: string | null;
};

export type SliceArticle = {
    id?: string;
    url: string;
    label?: string;
    expirableFlags?: ExpirableFlagsProps[];
    byline?: Byline;
    contentType?: ContentTypeText;
    hasVideo?: boolean;
    headline: string;
    shortSummary?: string;
    summary?: string;
    standfirst?: string;
    cta?: string;
    strapline?: string;
    datePublished?: string;
    dateUpdated?: string;
    template?: string;
    flag?: string;
    tag?: TagType | null;
    images: Images;
    video?: Video;
    carousel?: CuratedMedia[];
    categoryPath?: string;
};

export type SliceSlot = {
    article?: SliceArticle;
    component?: SliceComponent;
    title?: string;
    link?: CuratedSectionLink;
};

export type SliceData = {
    name: string;
    children: SliceSlot[];
    title?: string;
    sliceBlockLink?: string;
    slotName?: string;
};
