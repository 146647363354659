import { getMediaQuery, formatTypographyPresetToCss, getRatio } from '@/utils';
import styled from '@emotion/styled';
import { GridLayoutItem } from '@/components/slices';
import { Stack } from '@/components/ui';

export const ListTextBlock = styled.span`
    display: inline-block;
    margin-inline-end: ${(props) => props.theme.spacePresets.space060};
    margin-block-end: ${(props) => props.theme.spacePresets.space040};
    margin-block-start: -4px;
    ${(props) =>
        formatTypographyPresetToCss(
            props.theme.typographyPresets.utilityLabel005
        )};
    color: ${(props) => props.theme.colors.inkBase};
    &::before {
        content: '';
        display: block;
        margin-bottom: -0.2035em;
    }

    &::after {
        content: '';
        display: block;
        margin-top: -0.2215em;
    }
`;

export const StyledBlock = styled.div`
    .lead-media-container {
        display: none;
    }

    ${getMediaQuery('md')} {
        width: 579px;
        .lead-media-container {
            display: inline;
        }
    }
    ${getMediaQuery('lg')} {
        width: 501px;
        .lead-media-container {
            display: inline;
        }
    }
    ${getMediaQuery('xl')} {
        width: 683px;
        .lead-media-container {
            display: inline;
        }
    }
`;

export const StyledMainDivider = styled.div`
    ${getMediaQuery('md')} {
        width: 719px;
    }
    ${getMediaQuery('lg')} {
        width: 642px;
    }
    ${getMediaQuery('xl')} {
        width: 812px;
    }
`;

export const StyledAdWrapper = styled.div<{
    isBrowseByDay?: boolean;
}>`
    p {
        color: #aaaaaa;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        padding-block: 2px;
        position: ${({ isBrowseByDay }) =>
            isBrowseByDay ? 'static' : 'sticky'};
        top: 110px;
    }

    ${getMediaQuery('md')} {
        display: none;
    }
    ${getMediaQuery('lg')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-inline-start: ${(props) => props.theme.spacePresets.space060};
    }
`;
export const StyledAdContainer = styled.div<{
    isBrowseByDay?: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: ${({ isBrowseByDay }) => (isBrowseByDay ? 'static' : 'sticky')};
    top: 134px;
`;

export const StyledAdBlock = styled.div`
    height: 250px;
    background: #ff5858;
    margin-bottom: 30px;
    ${getMediaQuery('md')} {
        margin: 0 0 30px 0;
    }

    ${getMediaQuery('lg')} {
        width: 300px;
        height: 600px;
        background: #ff5858;
    }
`;

export const StyledDateText = styled.span`
    display: block;
    ${(props) =>
        formatTypographyPresetToCss(
            props.theme.typographyPresets.utilityLabel005
        )};
    color: ${(props) => props.theme.colors.inkBase};
    white-space: nowrap;
    width: 109px;
`;

export const AdBlockWrapperMedium = styled.div`
    margin-left: -170px;
    margin-right: -30px;
`;

export const LoadingBlock = styled.div<{
    height: string;
    width?: string;
    marginBlockEnd?: string;
    isBrowseByDay?: boolean;
}>`
    ${({ marginBlockEnd, theme }) =>
        marginBlockEnd &&
        `margin-block-end: ${theme.spacePresets[marginBlockEnd]};`}
    background-color: ${({ theme, isBrowseByDay }) =>
        isBrowseByDay ? theme.colors.white : theme.colors.neutral010};
    ${({ width }) => width && `width: ${width || '100%'};`} ${({ width }) =>
        !width && getMediaQuery('md')} {
        width: 407px;
    }
    ${({ width }) => !width && getMediaQuery('lg')} {
        width: 331px;
    }
    ${({ width }) => !width && getMediaQuery('xl')} {
        width: 470px;
    }
    height: ${({ height, theme }) =>
        theme.sizing[height] ? theme.sizing[height] : height};
`;

export const Button = styled.a<{ disabled?: boolean }>`
    margin: 0;
    padding: 0;
    display: inline-grid;
    width: 100%;
    column-gap: 8px;
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;
    min-width: 80px;
    min-height: 48px;
    padding-inline: 16px;
    padding-block: 12px;
    box-sizing: border-box;
    text-decoration: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    overflow: hidden;
    border: none;
    appearance: none;
    background-color: #00000000;
    border-style: solid;
    border-color: ${({ disabled }) => (disabled ? '#eeeeee' : '#333333')};
    border-width: 1px;
    border-radius: 0px;
    color: ${({ disabled }) => (disabled ? '#aaaaaa' : '#333333')};
    cursor: pointer;
    ${(props) =>
        formatTypographyPresetToCss(
            props.theme.typographyPresets.utilitySubheading020
        )};
`;

export const FullWidthGridLayoutItem = styled(GridLayoutItem)<{
    ratio?: string;
    className?: string;
    marginBlockEnd?: string;
}>`
  height: ${({ className }) =>
      className && className !== 'article-image' ? 0 : '100%'};
  ${({ marginBlockEnd, theme }) =>
      marginBlockEnd &&
      `margin-block-end: ${theme.spacePresets[marginBlockEnd]};`}
  overflow: hidden;
  position: relative;
  padding-bottom: ${({ ratio }) => (ratio ? `${100 / getRatio(ratio)}%;` : 0)};
  img: {
    opacity: 1,
    zIndex: 2,
    position: absolute,
    display: block
  }
  ${getMediaQuery('xs', 'md')} {
    margin-inline: ${({ theme }) => `-${theme.spacePresets.space045}`};
    padding-bottom: ${({ ratio }) =>
        ratio
            ? `calc(${100 / getRatio(ratio)}% + ${40 / getRatio(ratio)}px)`
            : 0};
  }`;

export const StyledImage = styled.img<{
    isRoundal?: boolean;
    aspectRatio?: string;
    isCommentBucket1?: boolean;
}>`
    object-fit: cover;
    max-width: 100%;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio && aspectRatio};
    border-radius: ${({ isRoundal }) => isRoundal && '50%'};
    width: ${({ isRoundal }) => isRoundal && '98px'};
    ${getMediaQuery('md', 'lg')} {
        margin-bottom: ${({ isCommentBucket1 }) => isCommentBucket1 && '16px'};
    }
`;

export const StyledStack = styled(Stack)`
    margin-inline: ${(props) => props.theme.spacePresets.space045};
    ${getMediaQuery('md')} {
        margin-inline: 0;
    }
`;
