import React from 'react';
import { FeaturesCarousel } from '@times-components/ts-components/rnw';
import { BlockHeader } from '@times-web/newskit-components';
import { getSliceHeaderClickEvent } from '@times-web/page-channel';

type FeaturesCarouselItemProps = {
    title: string;
    text: string;
    image: string;
    color: {
        bg: string;
        title: string;
    };
    url: string;
};

type FeaturesCarouselProps = {
    heading: string;
    carouselItems: FeaturesCarouselItemProps[];
    isEditionHeading: boolean;
    isChannelHeading: boolean;
};

export const BenefitsCarousel = ({
    dataFile = 'fixtureData.json',
    isEditionHeading = false,
    isChannelHeading = false
}) => {
    const [data, setData] = React.useState<FeaturesCarouselProps | null>(null);
    React.useEffect(() => {
        fetch(`https://extras.thetimes.co.uk/web/benefits-carousel/${dataFile}`)
            .then((res) => res.json())
            .then(({ featuresCarouselData }) => setData(featuresCarouselData))
            .catch((err) => console.error('Error: FeaturesCarousel', err));
    }, [dataFile]);

    return data ? (
        <div className={isEditionHeading ? 'Slice Slice--container' : ''}>
            {isEditionHeading && (
                <h2>{data.heading}</h2>
            )}
            {isChannelHeading && (
                <BlockHeader title={data.heading} blockHeaderClickHandler={getSliceHeaderClickEvent} />
            )}
            <FeaturesCarousel
                heading={data.heading}
                carouselItems={data.carouselItems}
                hideHeading={isEditionHeading || isChannelHeading}
            />
        </div>
    ) : null;
};