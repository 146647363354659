import React, { Fragment } from 'react';
import { Divider, Block } from '@/components/ui';
import { LoadingBlock } from '../styles';
import { SliceLayout } from '@/components/slices';

export const ListViewSliceLoading = ({
    itemsPerPage,
    isBrowseByDay,
}: {
    itemsPerPage: number;
    isBrowseByDay?: boolean;
}) => {
    return (
        <SliceLayout>
            {Array.from(Array(itemsPerPage).keys()).map((item) => (
                <Fragment key={item}>
                    <Block data-testid="list-view-slice-loading">
                        <LoadingBlock
                            height="sizing020"
                            width="109px"
                            marginBlockEnd="space030"
                            isBrowseByDay={isBrowseByDay}
                        />
                        <LoadingBlock
                            marginBlockEnd="space030"
                            height="sizing020"
                            width="185px"
                            isBrowseByDay={isBrowseByDay}
                        />
                        <LoadingBlock
                            marginBlockEnd="space030"
                            height="sizing050"
                            isBrowseByDay={isBrowseByDay}
                        />
                        <LoadingBlock
                            marginBlockEnd="space030"
                            height="sizing080"
                            isBrowseByDay={isBrowseByDay}
                        />
                        <LoadingBlock
                            marginBlockEnd="space030"
                            height="sizing020"
                            width="110px"
                            isBrowseByDay={isBrowseByDay}
                        />
                    </Block>
                    {item !== itemsPerPage - 1 && (
                        <Block marginBlock={{ all: 'space040' }}>
                            <Divider variant="lightDashed" />
                        </Block>
                    )}
                </Fragment>
            ))}
        </SliceLayout>
    );
};
