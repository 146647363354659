import React from 'react';
import { Route, Switch } from 'react-router';
import { getChannelRoutesByType } from '@times-web/utils';

// Polyfills
import 'scroll-behavior-polyfill';
import 'url-polyfill';
import { ChannelType } from './types/channel';

import { Curated } from './pages/Curated/Curated';
import { Preloaded } from './pages/Preloaded/Preloaded';

const Channels = () => (
    <Switch>
        <Route exact path={['/', '/_TP_', '/_tp_']} component={Curated} />
        <Route
            exact
            path={getChannelRoutesByType(ChannelType.Curated)}
            component={Curated}
        />
        <Route path={'/preview/channel/:id'} component={Curated} />
        <Route
            exact
            path={getChannelRoutesByType(ChannelType.Preloaded)}
            component={Preloaded}
        />
    </Switch>
);

export * from './components/Ads/ListAd';
export * from './components/Ads/HeaderAd';
export * from './components/Ads/SectionAd';
export * from './data/getChannelData';
export * from './queries/curatedPage';
export * from './types/channel';
export * from './utils/convertCuratedData';
export * from './utils/convertTpaData';
export * from './utils/get-media-query';
export { formatListSlice } from './utils/formatListSlice';
export {
    NewHeaderAdContainer,
    Container,
    NewSliceSectionContainer,
} from './components/shared/styles';
export { getSliceHeaderClickEvent } from './utils/getAnalyticsEvents';
export { getChannelTheme } from './utils/getChannelTheme';

export default Channels;
